h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.template-item {
    margin: 10px 0px;
    padding: 18px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.template-item hr {
    margin: 10px 0;
    border: none;
    border-top: 1px dashed #ccc;
}

.dynamic-input {
    width: 20%;
    padding: 5px;
    margin: 5px 10px 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.template-text {
    white-space: pre-wrap;
    font-size: 18px;
    line-height: 1.5;
    margin-top: 10px;
}

.program-link {
    font-size: 14px;
    color: #337ab7;
}

.card2 {
    padding: 25px;
    text-align: left;
}

.button-cln {
    background-color: #7a117a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 12px;
    border: 1px solid #7a117a;
    width: 170px;
}

.button-cln:hover {
    background-color: #0056b3;
}